import React from 'react';
import {Footer, MainHeader, GamesList, InfoBanner} from "@homeplay/components";
import {selectNearestHHTexts, selectPtcGames} from "@homeplay/app";
import classes from './MainPage.module.scss';
import {useSelector} from "react-redux";
import logoPTC from "../../assets/images/ptc-logo.png";

const MainPage = () => {
    const hhTexts = useSelector(selectNearestHHTexts);
    const ptcGames = useSelector(selectPtcGames);

    return (
        <React.Fragment>
            <MainHeader/>
            <main className={classes.MainMenu}>
                {hhTexts && <InfoBanner texts={hhTexts} />}

                <div className="container">
                    <img className={classes.Logo} src={logoPTC} alt="Palm Treasures Club" width="237" height="144"/>
                    {ptcGames && <GamesList games={ptcGames}/>}
                </div>
            </main>
            <Footer/>
        </React.Fragment>
    );
};

export default MainPage;
